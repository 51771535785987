import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"
import PostPreview from "../components/post-preview"

const Uses = () => (
  <Layout>
    <SEO title="Uses" />

    <HeroSection backgroundColor="white">
      <div className="flex flex-col text-center items-center justify-center m-auto h-full">
        <h1 className="tracking-wide font-light">
          Adapted to <span className="font-bradford">you.</span>
        </h1>
      </div>
    </HeroSection>

    <PostPreview
      title="Yoga Studio"
      blurb={` Modal was created to make a difference. Our homes are sustainable,
    adaptable, affordable, and modern. We design unique spaces that are
    better you and better for our planet. We build without compromising
    quality or functionality. Our product perfectly embodies our goal as
    an organization: to do more with less. We're dedicated to providing
    a new mode of living.`}
    ></PostPreview>

    <PostPreview
      title="Mountain Cabin"
      blurb={`Modal was created to make a difference. Our homes are sustainable,
      adaptable, affordable, and modern. We design unique spaces that are
      better you and better for our planet. We build without compromising
      quality or functionality. Our product perfectly embodies our goal as
      an organization: to do more with less. We're dedicated to providing
      a new mode of living.`}
      flexDirection="row-reverse"
    ></PostPreview>

    <PostPreview
      title="Art Studio"
      blurb={` Modal was created to make a difference. Our homes are sustainable,
    adaptable, affordable, and modern. We design unique spaces that are
    better you and better for our planet. We build without compromising
    quality or functionality. Our product perfectly embodies our goal as
    an organization: to do more with less. We're dedicated to providing
    a new mode of living.`}
    ></PostPreview>

    <PostPreview
      title="Coffee Shop"
      blurb={`Modal was created to make a difference. Our homes are sustainable,
    adaptable, affordable, and modern. We design unique spaces that are
    better you and better for our planet. We build without compromising
    quality or functionality. Our product perfectly embodies our goal as
    an organization: to do more with less. We're dedicated to providing
    a new mode of living.`}
      flexDirection="row-reverse"
    ></PostPreview>

    <PostPreview
      title="Desert Retreat"
      blurb={` Modal was created to make a difference. Our homes are sustainable,
adaptable, affordable, and modern. We design unique spaces that are
better you and better for our planet. We build without compromising
quality or functionality. Our product perfectly embodies our goal as
an organization: to do more with less. We're dedicated to providing
a new mode of living.`}
    ></PostPreview>
  </Layout>
)

export default Uses
